<template>
    <dashboard-layout>
        <v-row>
            <v-col class="text-right">
                <v-btn :to="{name: 'admin.coupons.create'}">Create</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-data-table
                    :loading="loading"
                    loading-text="Loading... Please wait"
                    :headers="headers"
                    :items="coupons"
                    :items-per-page="15"
                    class="elevation-1"
                >
                    <template v-slot:item.id="{ item }">
                        <router-link :to="{name: 'admin.coupons.show', params: {id: item.id}}">
                            {{ item.id }}
                        </router-link>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </dashboard-layout>
</template>

<script>
import DashboardLayout from "@/layouts/DashboardLayout";
import Coupons from "@/models/Coupon";

export default {
    name: "Index",
    components: {DashboardLayout},
    data: function () {
        return {
            loading: false,
            coupons: [],
            headers: [
                {
                    text: '# ID',
                    align: 'start',
                    sortable: false,
                    value: 'id',
                },
                {text: 'Name', value: 'name'},
                {text: 'Percent off', value: 'percent_off'},
            ],
        }
    },
    async mounted() {
        await this.loadData()
    },
    methods: {
        async loadData() {
            this.loading = true
            this.coupons = await Coupons.get()
            this.loading = false
        }
    }
}
</script>

<style scoped>

</style>
